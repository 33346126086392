#loginform {
    padding: 70px;
}

.body {
    font-family: Segoe UI !important
}

.device-sidebar {
    width: 400px;
    right: 0;
    top: 70px
}

.device-control {
    width: 100%;
}

.device-control .input-label {
    font-size: 18px;
    font-weight: bold;
}

.device-control .slider-number {
    font-weight: bold;
}

.device-class-card {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
    text-align: center;
    color: #262d3c;
}

.device-class-card .logo {
    width: 86px;
    margin-bottom: 20px;
}

.button-big {
    width: 180px;
    height: 45px;
    margin: 20px 0 0;
    border-radius: 4px;
    border: solid 1px #1b7389;
    color: #1b7389;
    background-color: #ffffff;
}

.device-class-card .devices-in-project {
    padding-top: 5px;
    font-family: Heebo;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
    text-align: center;
    color: #262d3c;
}

.cursor-pointer {
    cursor: pointer;
}

.custom-button {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
    color: #164669;
}

.profile-dd {
    display: none;
}

.lang-selector {
    display: none;
}

.round-corner-top-left-wrapper {
    background-color: #001c4b !important;
    width: 10px;
    height: 10px;
}

.round-corner-top-left {
    background-color: #eaf1fd;
    width: 10px;
    height: 10px;
    border-radius: 10px 0px 0px 0px;
}

[data-theme='dark'] .round-corner-top-left {
    background-color: #000000;
}

.air-quality-text-section {
    margin-top: 45px;
}

.air-quality-line {
    margin-bottom: 10px;
}

.air-quality-number {
    font-size: 20px;
    font-weight: bold;
    padding-right: 10px;
    width: 20px;
}

.air-quality-text {
    color: #646464;
    padding-left: 10px;
    font-size: 14px;
}

.air-quality-state-good {
    color: #1697e2;
    font-size: 20px;
}

.air-quality-state-moderate {
    color: #04b27d;
    font-size: 20px;
}

.air-quality-state-poor {
    color: #fed230;
    font-size: 20px;
}

.air-quality-state-critical {
    color: #d61e3c;
    font-size: 20px;
}

.air-quality-state-off {
    font-size: 20px;
}

.devices-attention {
    color: #d61e3c;
    font-size: 30px;
    margin-right: 10px;
}

.devices-all-is-good {
    color: #1697e2;
    font-size: 30px;
    margin-right: 10px;
}

.devices-offline {
    font-size: 30px;
    margin-right: 10px;
}

[data-theme="light"].devices-number {
    font-size: 30px;
    font-weight: bold;
    padding-right: 10px;
    width: 20px;
    color: #000000;
}
[data-theme="dark"].devices-number {
    font-size: 30px;
    font-weight: bold;
    padding-right: 10px;
    width: 20px;
    color: white;
}

.devices-line {
    margin-bottom: 20px;
}

.breadcrumb-item-link {
    color: #646464;
}

/*
.navbarbg{
    background-color: #ffffff !important;
}

.navbar-header{
    background-color: #13396a !important;
}

.header-user-text {
    color: #15102e;
}
*/
.header-light-dark-mode {
    margin-top: 25px;
    margin-right: 30px;
}

.light-mode-label {
    color: #eaf1fd;
}

.dark-mode-label {
    color: #eaf1fd;
}


.header-profile-name h5 {
    padding-top: 18px;
    color: #eaf1fd;
}

.header-profile-name h6 {
    color: #eaf1fd;
}

.header-profile-arrow {
    font-size: 14px;
    margin-top: 10px;
}

.header-profile-popup-color {
    background-color: #13396a;
}

.devicesCardsImg {
    margin-left: 0px;
    padding-bottom: 10px;
}

.devicesIconsHead {
    margin-right: 10px;
    width: 24px;
    height: 24px;
}

.filterContainer {
    position: relative;
    padding: 0 0 0 0 !important;
    margin: 0;
}

.filterInput {
    margin: 0;
    font-size: 15px;
    color: black;
    height: 30px;
    width: 318px;
    text-align: left;
    padding-left: 2em;
    cursor: text;
}

.filterMagnify {
    position: absolute;
    left: 5px;
    bottom: 24px;
    width: 10px;
    height: 10px;
}

.filterBtnImg {
    width: 22px;
    height: 22px;
}

[data-theme="light"].filterBtn {
    background-color: #ffffff;
    margin-left: 8px;
    width: 30px;
    height: 30px;
    padding: 0px 0px 0px 0px;
    border-width: 0px;
}
[data-theme="dark"].filterBtn {
    background-color: #ffffff;
    margin-left: 8px;
    width: 30px;
    height: 30px;
    padding: 0px 0px 0px 0px;
    border-width: 0px;
}
[data-theme="light"].filterBtns:disabled {
    background-color: #f1f1f1;
    margin-left: 8px;
    width: 30px;
    height: 30px;
    padding: 0px 0px 0px 0px !important;
    border-width: 0px;
}
[data-theme="dark"].filterBtns:disabled {
    background-color: #292c33;
    margin-left: 8px;
    width: 30px;
    height: 30px;
    padding: 0px 0px 0px 0px !important;
    border-width: 0px;
}
[data-theme="light"].filterBtns {
    background-color: #ffffff;
    margin-left: 8px;
    width: 30px;
    height: 30px;
    padding: 0px 0px 0px 0px;
    border-width: 0px;
}

[data-theme="dark"].filterBtns {
    background-color: #292c33;
    margin-left: 8px;
    width: 30px;
    height: 30px;
    padding: 0px 0px 0px 0px;
    border-width: 0px;
}

[data-theme="light"].filterBtnsView {
    background-color: #ffffff;
    width: 30px;
    height: 30px;
    padding: 0px 0px 0px 0px;
    border-width: 0px;
}
[data-theme="dark"].filterBtnsView {
    background-color: #1d1b23 ;
    width: 30px ;
    height: 30px ;
    padding: 0px 0px 0px 0px;
    border-width: 0px ;
}

[data-theme="light"].btn-secondary:not(:disabled):not(.disabled).active {
    background-color: #1b71f1;
    fill: white;
}
[data-theme="dark"].btn-secondary:not(:disabled):not(.disabled).active {
    background-color: #4f93fc !important;
    /* fill: white; */
}

.filterInfoCards {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
}

.devicesCards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.cardsIcons {
    font-size: 26px;
}

.tableIcons {
    font-size: 22px;
}

.cardsPics {
    height: 26px;
    width: 26px;

}

.cardsNumbers {
    font-size: 30px;
    font-family: SegoeUI-Semibold, Segoe UI;
    font-weight: 600
}

[data-theme="light"].cardText {
    fill: #646464;
    font-size: 14px;
    font-family: SegoeUI, Segoe UI;
}
[data-theme="dark"].cardText {
    color: #a2a2a2;
    font-size: 14px;
    font-family: SegoeUI, Segoe UI;
}

[data-theme="light"].rt-th {
    background-color: #434054;
    color: #dfe4ee
}
[data-theme="light"].rt-thead{
    background-color: #292c33 !important;
    color: #dfe4ee !important
}

.tableArrow {
    font-size: 22px;
    margin-right: 20px;
}

[data-theme="light"].tableHeaders {
    font-size: 16px;
    font-weight: bold;
    color: #000000 !important;
}
.rt-tr-group.span{
    margin-bottom: 10px;
}
/* .filterDeviceCards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr; */
/* } */

.limitCardsContainerHeight {
    max-height: 600px;
    margin-left: 15px;
}

.tableContainer {
    padding: 0 0 0 0;
    margin-bottom: 15px;
}

.wifiSize {
    font-size: 16px;
}

[data-theme="light"].rt-td {
    font-size: 16px;
    color: #000000;
    /* height: 35px; */
}

[data-theme="light"].deviceTableDeviceName {
    color: #1b71f1;
    text-decoration: underline;
    font-size: 16px;
}
[data-theme="dark"].deviceTableDeviceName {
    color: #4f93fc;
    text-decoration: underline;
    font-size: 16px;
}

[data-theme="light"].div.rt-tr {
    align-items: center;
    background-color: #434054 !important;
}

.deviceCardGrid {
    display: grid;
    grid-template-columns: 1fr 2fr
}

.deviceCardDeviceName {
    display: block;
    color: black;
    font-size: 18px;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
[data-theme="light"].deviceCardName{
     color:black
}
[data-theme="dark"].deviceCardName{
    color:white
}
.schduleDeviceName {
    display: inline-block;
    color: black;
    font-size: 20px;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.assignScheduleHead {
    font-size: 26px;
    margin-left: 20px;
    font-weight: bold;
    /* color: black; */

}

.deviceSidebarDeviceName {
    display: block;
    color: black;
    font-size: 18px;
    max-width: 240px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

[data-theme="light"].deviceCardAIrQuality {
    color: black;
    width: 150px
}
[data-theme="dark"].deviceCardAIrQuality {
    color: white;
    width: 150px
}

.deviceCardIcons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    font-size: 24px;
}

.deviceCardIaqOne {
    position: absolute;
    top: 28px;
    left: 44%;
}

.deviceCardIaqTwo {
    position: absolute;
    top: 28px;
    left: 38%;
}

.deviceCardIaqThree {
    position: absolute;
    top: 28px;
    left: 30%;
}

[data-theme="light"].deviceSidebarDashboard {
    background-color: #eaf1fd
}
[data-theme="dark"] .deviceSidebarDashboard{
    background-color: #292c33
}

[data-theme="light"].deviceSidebarCard {
    display: flex;
    width: 366px;
    height: 760px;
    left: 18px;
    margin: 0 0 0 0 !important;
}
[data-theme="dark"].deviceSidebarCard {
    display: flex;
    width: 366px;
    height: 760px;
    left: 18px;
    margin: 0 0 0 0 !important;
    background-color:#121212
}

.deviceDashboardArrow {
    color: #000000;
    font-size: 16px;
    font-weight: 900;
}

.deviceDashboardCardHead {
    color: #000000;
}
[data-theme="dark"].deviceDashboardCardHead {
    color: #ffffff;
    background-color: #121212 !important;
}

[data-theme="light"].deviceSidebarLock {
    position: absolute;
    right: 70px
}
[data-theme="dark"].deviceSidebarLock {
    position: absolute;
    right: 70px;
    color: #a2a2a2
}

[data-theme="light"].deviceSidebarOff {
    position: absolute;
    right: 20px
}
[data-theme="dark"].deviceSidebarOff {
    position: absolute;
    right: 20px;
    background-color: #121212;
    color: #a2a2a2
}

.deviceSideHR {
    margin-top: 10px !important;
    margin-left: -20px !important;
    margin-right: -20px !important;
}

.deviceSideHR2 {
    margin-left: -20px !important;
    margin-right: -20px !important;
}

.deviceCardBody {
    margin-top: -10px;
}

[data-theme="light"].deviceSidebarSchedule {
    color: #000000;
    font-size: 20px;
}
[data-theme="dark"].deviceSidebarSchedule {
    color: #ffffff;
    font-size: 20px;
    background-color: #121212 !important
}


.deviceSidebarIAQ1 {
    position: absolute;
    top: 300px;
    left: 160px;
    text-align: center;
}

.deviceSidebarIAQ2 {
    position: absolute;
    top: 300px;
    left: 157px;
    text-align: center;
}

.deviceSidebarIAQ3 {
    position: absolute;
    top: 300px;
    left: 141px;
    text-align: center;
}

.iaqSidebar1 {
    font-size: 46px;
    margin-bottom: 0px;
}

.iaqSidebar2 {
    font-size: 46px;
    margin-bottom: 0px;
}

.iaqSidebar3 {
    font-size: 46px;
    margin-bottom: 0px;
}

[data-theme="light"].sidebarQ1 {
    color: #000000;
    font-size: 16px;
    top: -10px;
}
[data-theme="dark"].sidebarQ1 {
    color: #ffffff !important;
    font-size: 16px;
    top: -10px;
    background-color: #121212 !important;
}

[data-theme="light"].sidebarQ2 {
    color: #000000;
    font-size: 16px;
    top: -10px;
}
[data-theme="dark"].sidebarQ2 {
    color: #ffffff !important;
    font-size: 16px;
    top: -10px;
    background-color: #121212 !important;
}
[data-theme="light"].sidebarQ3 {
    color: #000000;
    font-size: 16px;
    top: -10px;
}
[data-theme="dark"].sidebarQ3 {
    color: #ffffff !important;
    font-size: 16px;
    top: -10px;
    background-color: #121212 !important;
}

.sidebarFanText {
    color: #000000;
    font-size: 18px;
}

.fan1 {
    width: 44px;
    height: 20px;
    margin: 0 1px 0 0;
    border-radius: 9px 0px 0px 9px;
}

.fan2 {
    width: 44px;
    height: 20px;
    margin: 0 1px;
}

.fan3 {
    width: 44px;
    height: 20px;
    margin: 0 1px;
}

.fan4 {
    width: 44px;
    height: 20px;
    margin: 0 1px;
}

.fan5 {
    width: 44px;
    height: 20px;
    margin: 0 1px;
}

.fan6 {
    width: 44px;
    height: 20px;
    margin: 0 1px;
}

.fan7 {
    width: 44px;
    height: 20px;
    margin: 0 1px 0 0;
    border-radius: 0px 9px 9px 0px;
}

.addFan1 {
    width: 35px;
    height: 20px;
    margin: 0 1px 0 0;
    border-radius: 9px 0px 0px 9px;
}

.addFan2 {
    width: 35px;
    height: 20px;
    margin: 0 1px;
}

.addFan3 {
    width: 35px;
    height: 20px;
    margin: 0 1px;
}

.addFan4 {
    width: 35px;
    height: 20px;
    margin: 0 1px;
}

.addFan5 {
    width: 35px;
    height: 20px;
    margin: 0 1px;
}

.addFan6 {
    width: 35px;
    height: 20px;
    margin: 0 1px;
}

.addFan7 {
    width: 35px;
    height: 20px;
    margin: 0 1px 0 0;
    border-radius: 0px 9px 9px 0px;
}

[data-theme="light"].disableclock {
    color: #d1d1d1
}
[data-theme="dark"].disableclock {
    color: #646464
}

.chartDiv {
    display: inline-block;
    position: relative;
}

.alertRed {
    color: red
}

.bellYellow {
    color: rgb(248, 204, 44)
}

.cardIcons {
    font-size: 30px
}

.sensorsSide {
    padding-left: "30px";
    padding-right: "10px";
    color: "#1b71f1";
    border-top: #1b71f1 1px;
}

[data-theme="light"].sensorsSideTele {
    font-size: 18px;
    font-weight: bold;
    color: black;
    padding-left: 20px;
}
[data-theme="dark"].sensorsSideTele {
    font-size: 18px;
    font-weight: bold;
    color: white;
    padding-left: 20px;
}

.bg-good {
    background-color: #9dd2f3 !important;
}

.bg-moderate {
    background-color: #96ddcb !important
}

.bg-poor {
    background-color: #f8e8aa !important
}

.bg-critical {
    background-color: #eba2b1 !important;
}

.gridSchedule {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 7fr;
}

.daysGrid {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

[data-theme="light"].daysBorder1 {
    position: absolute;
    right: 46.5px;
    border-right: 2px solid #dfe4ee;
    height: 32px;
    top: 0px
}

[data-theme="light"].daysBorder2 {
    position: absolute;
    right: 91px;
    border-right: 2px solid #dfe4ee;
    height: 32px;
    top: 0px
}

[data-theme="light"].daysBorder3 {
    position: absolute;
    right: 137.5px;
    border-right: 2px solid #dfe4ee;
    height: 32px;
    top: 0px
}

[data-theme="light"].daysBorder4 {
    position: absolute;
    right: 182.5px;
    border-right: 2px solid #dfe4ee;
    height: 32px;
    top: 0px
}

[data-theme="light"].daysBorder5 {
    position: absolute;
    right: 228px;
    border-right: 2px solid #dfe4ee;
    height: 32px;
    top: 0px
}

[data-theme="light"].daysBorder6 {
    position: absolute;
    right: 273.5px;
    border-right: 2px solid #dfe4ee;
    height: 32px;
    top: 0px
}

[data-theme="light"].daysBorder7 {
    position: absolute;
    right: 319px;
    border-right: 2px solid #dfe4ee;
    height: 32px;
    top: 0px
}

[data-theme="dark"].daysBorder1 {
    position: absolute;
    right: 46.5px;
    border-right: 2px solid #3c3d44 !important;
    height: 32px;
    top: 0px
}

[data-theme="dark"].daysBorder2 {
    position: absolute;
    right: 91px;
    border-right: 2px solid #3c3d44 !important;
    height: 32px;
    top: 0px
}

[data-theme="dark"].daysBorder3 {
    position: absolute;
    right: 137.5px;
    border-right: 2px solid #3c3d44 !important;
    height: 32px;
    top: 0px
}

[data-theme="dark"].daysBorder4 {
    position: absolute;
    right: 182.5px;
    border-right: 2px solid #3c3d44 !important;
    height: 32px;
    top: 0px
}

[data-theme="dark"].daysBorder5 {
    position: absolute;
    right: 228px;
    border-right: 2px solid #3c3d44 !important;
    height: 32px;
    top: 0px
}

[data-theme="dark"].daysBorder6 {
    position: absolute;
    right: 273.5px;
    border-right: 2px solid #3c3d44 !important;
    height: 32px;
    top: 0px
}

[data-theme="dark"].daysBorder7 {
    position: absolute;
    right: 319px;
    border-right: 2px solid #3c3d44 !important;
    height: 32px;
    top: 0px
}
.daysScheGrid {
    display: grid;
    grid-template-rows: repeat(12, 1fr);
    grid-template-columns: repeat(7, 1fr);
}

[data-theme="light"].scheduleInsideBorders {
    border: 1px solid #dfe4ee !important;
}
[data-theme="dark"].scheduleInsideBorders {
    border: 1px solid #3c3d44 !important;
    z-index: 15;
}

.eventPlace {
    z-index: 20￼;
    border-radius: 12px 12px 12px 12px;
}
.eventPlaceStart {
    z-index: 20￼;
    border-radius: 12px 12px 0px 0px;
}
.eventPlaceEnd {
    z-index: 20￼;
    border-radius: 0px 0px 12px 12px;
}

.logout-modal .modal-body {
    padding: 0;
}

.edit-modal .modal-content {
    border-radius: 10px;
}

.edit-modal p {
    font-weight: bold;
    color: #272e50;
    font-size: 20px;
    padding: 70px 80px 0px 50px;
    font-family: inherit;
}

.edit-modal img {
    padding: 70px 15px 60px 15px;
}

.edit-modal .button-section {
    margin: 40px 50px 0 70px;
    justify-content: center;
    align-items: center;
}

[data-theme="light"].yes-btn {
    width: 90%;
    height: 45px;
    margin-left: 0;
    background-color: #1b71f1;
    fill: white;
    color: white;
    border: 0;
    border-radius: 4px;

}

[data-theme="light"].no-btn {
    width: 90%;
    margin-left: 0;
    background-color: white;
    border-radius: 4px;
    color: #4f93fc;
    height: 45px;
    border: 1px solid #4f93fc;
}
[data-theme="dark"] .yes-btn {
    width: 90%;
    height: 45px;
    margin-left: 0;
    background-color: #4f93fc;
    fill: white;
    color: white;
    border: 0;
    border-radius: 4px;

}

[data-theme="dark"] .no-btn {
    width: 90%;
    margin-left: 0;
    background-color: white;
    border-radius: 4px;
    color: #1b71f1;
    height: 45px;
    border: 1px solid #1b71f1;
}

[data-theme="light"].closeBtn{
    width: 90%;
    margin-left: 0;
    background-color: white;
    border-radius: 4px;
    color: #4f93fc;
    height: 45px;
    border: 1px solid #4f93fc;
    margin-top:10px;
}
[data-theme="light"].removeBtn{
    width: 160px;
    height: 44px;
    margin-left: 15px;
    background-color: #1b71f1;
    fill: white;
    color: white;
    border: 0;
    border-radius: 4px;
    margin-top:10px;
}
[data-theme="dark"].closeBtn{
    width: 90%;
    margin-left: 0;
    background-color: #292c33;
    border-radius: 4px;
    color: #4f93fc;
    height: 45px;
    border: 1px solid #4f93fc;
    margin-top:10px;
}
[data-theme="dark"].removeBtn{
    width: 160px;
    height: 45px;
    margin-left: 15px;
    background-color: #4f93fc !important;
    fill: white;
    color: white;
    border: 0;
    border-radius: 4px;
    margin-top:10px;
}

.edit-modal .col {
    padding: 0;
    margin: 0;
}

.modalHead {
    font-size: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    font-weight: bold
}
[data-theme="light"].modalHead {
    color: black;
}
[data-theme="dark"].modalHead {
    color: white;
}
/* .button-section{
    align-items: center;
    justify-content: center;
} */
.disabledButton {
    background-color: grey;
}

[data-theme="light"].cancelScheduleButton {
    width: 100px;
    height: 40px;
    padding: 6px 16px 7px;
    border-radius: 6px;
    background-color: white;
    color: #1b71f1;
    font-size: 16px;
}

[data-theme="light"].assignScheduleButton {
    width: 100px;
    height: 40px;
    padding: 6px 16px 7px;
    border-radius: 6px;
    background-color: #1b71f1;
    font-size: 16px;
}

[data-theme="dark"].cancelScheduleButton {
    width: 100px;
    height: 40px;
    padding: 6px 16px 7px;
    border-radius: 6px;
    background-color: white;
    color: #bfc1c5;
    font-size: 16px;
}

[data-theme="dark"].assignScheduleButton {
    width: 100px;
    height: 40px;
    padding: 6px 16px 7px;
    border-radius: 6px;
    background-color: #4f93fc !important;
    font-size: 16px;
}


[data-theme="light"].dayOn {
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 6px;
    border: solid 1px #1b71f1;
    background-color: #1b71f1 !important;
    color: white;
    font-size: 12px;
    font-weight: 400;
}
[data-theme="light"].dayOff { 
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 6px;
    border: solid 1px #1b71f1;
    color: #1b71f1;
    font-size: 12px;
    font-weight: 400;
}
[data-theme="dark"].dayOn {
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 6px;
    border: solid 1px #4f93fc;
    background-color: #4f93fc !important;
    color: white;
    font-size: 12px;
    font-weight: 400;
}
[data-theme="dark"].dayOff { 
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 6px;
    border: solid 1px #4f93fc;
    color: #4f93fc;
    font-size: 12px;
    font-weight: 400;
}

.limitScheduleNameModal {
    display: block;
    color: black;
    font-size: 18px;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

[data-theme="light"].addSlotBtn{
    height: 34px;
    width: 100px;
    color: #1b71f1;
    background-color: white;
    border: solid 1px #1b71f1;
    border-radius: 6px;
    margin-left: 40px;
    margin-right: 130px;
}
[data-theme="dark"].addSlotBtn{
    height: 34px;
    width: 100px;
    color: #4f93fc;
    background-color: white;
    border: solid 1px #4f93fc;
    border-radius: 6px;
    margin-left: 40px;
    margin-right: 130px;
}
.deleteSlotBtn{
    height: 34px;
    width: 100px;
    color: white;
    background-color: #d61e3c;
    border-radius: 6px;
}
[data-theme="light"].cancelBtn{
    height: 34px;
    width: 100px;
    color: #1b71f1;
    background-color: white;
    border: solid 1px #1b71f1;
    border-radius: 6px;
    margin-left: 160px;
    margin-right: 20px;
}
[data-theme="light"].assignBtn{
    height: 34px;
    width: 100px;
    color: white;
    background-color: #1b71f1;
    border-radius: 6px;
}
[data-theme="dark"].cancelBtn{
    height: 34px;
    width: 100px;
    color: #4f93fc;
    border: solid 1px #4f93fc;
    background-color: #292c33 !important;
    border-radius: 6px;
    margin-left: 160px;
    margin-right: 20px;
}
[data-theme="dark"].assignBtn{
    height: 34px;
    width: 100px;
    color: white;
    background-color: #4f93fc !important;
    border-radius: 6px;
}
[data-theme="light"].nobtn{
    height: 44px;
    width: 140px;
    color: #1b71f1;
    background-color: white;
    border: solid 1px #1b71f1;
    border-radius: 6px;
    margin-left: 100px;
    margin-right: 20px;
    margin-top:10px
}
[data-theme="light"].yesbtn{
    height: 44px;
    width: 140px;
    color: white;
    background-color: #1b71f1;
    border-radius: 6px;
    margin-top:10px
}
[data-theme="dark"].nobtn{
    height: 44px;
    width: 140px;
    color: #4f93fc;
    border: solid 1px #4f93fc;
    background-color: #292c33 !important;
    border-radius: 6px;
    margin-left: 40px;
    margin-right: 20px;
    margin-top:10px
}
[data-theme="dark"].yesbtn{
  height: 44px;
    width: 140px;
    color: white;
    background-color: #4f93fc !important;
    border-radius: 6px;
    margin-top:10px
}
.ps__thumb-x{
    display: none !important;
}
.ps__rail-x{
    display: none  !important;
}

.cancelBtnUsers{
    height: 34px;
    width: 100px;
    color: #1b71f1;
    background-color: white;
    border: solid 1px #1b71f1;
    border-radius: 6px;
    margin-left: 150px;
    margin-right: 20px;
}
.addBtnUsers{
    height: 34px;
    width: 100px;
    color: white;
    background-color: #1b71f1;
    border-radius: 6px;
}

[data-theme="light"].first-level .sidebar-item.selected > .sidebar-link {
    color: #607d8b !important;
}

.dark-logo-text {
    width: 95%;
    margin-left: 10px;
}
.organizationRow{
    display: grid;
    grid-template-columns: 4fr 2fr 2fr 2fr 1fr;
}
.organizationRowFloor{
    display: grid;
    grid-template-columns: 4fr 2fr 2fr 2fr 1fr;
    padding: 0px -15px 0px -15px;
}
[data-theme="dark"] .page-wrapper{
    background-color:#000000 !important;
    color: #ffffff
}
[data-theme="dark"] .breadcrumb{
    background-color:#000000 !important
}
[data-theme="dark"].tableHeaders{ 
    color:#fff;
    font-size: 16px;
    font-weight: bold;
}

[data-theme="dark"].darkCard{
    background-color:#1d1b23
}
[data-theme="dark"].rt-td{
    font-size: 16px;
    background-color:#1d1b23 !important;
    color:#ffffff !important
}

[data-theme="dark"].deviceTableHeaders{
    color:#a2a2a2 !important
}
[data-theme="light"].deviceTableHeaders{
    color:white !important;
    background-color: #434054 !important
}
[data-theme="dark"] .rt-resizable-header{
    background-color: #292c33 !important;
    color:#a2a2a2
}
[data-theme="light"] .rt-resizable-header{
    background-color: #434054 ;
    color: white
}
[data-theme="dark"] .devices-text{
    color:#a2a2a2
}
[data-theme="dark"] .sidebarTheme{
    background-color: #292c33
}
[data-theme="dark"] .deviceCardBody{
    background-color: #121212
}
[data-theme="dark"] .deviceDashboardArrow{
    color: #a2a2a2
}
[data-theme="dark"] .fanSpeedColor{
    color:white
}
[data-theme="light"] .fanSpeedColor{
    color:black
}
[data-theme="dark"] .sidebarBottomText{
    background-color: #292c33 !important;
    color:#969ca5
}
[data-theme="light"] .sidebarBottomText{
    color:black
}

[data-theme="dark"] .progress{
    background-color: #434154;
    border: 1px solid inside #3c3d44
}

[data-theme="light"].progress{
    background-color: white;
    border: 1px solid inside #aeb9cf
}
[data-theme="light"].yes-btn-logout{
    width: 100%;
    height: 45px;
    margin-left: 0;
    background-color:#1b71f1;
    color: white;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
}
[data-theme="light"].no-btn-logout{
    width: 100%;
    margin-left: 0;
    background-color: white;
    border: 1px solid #1b71f1 !important;
    color: #1b71f1;
    height: 45px;
    border: 0;
    font-size: 16px;
    font-weight: bold;
}
[data-theme="dark"].yes-btn-logout{
    width: 100%;
    height: 45px;
    margin-left: 0;
    background-color:#4f93fc !important;
    color: white;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
}
[data-theme="dark"].no-btn-logout{
    width: 100%;
    margin-left: 0;
    background-color: #1d1b23;
    border: 1px solid #4f93fc !important;
    color: #4f93fc;
    height: 45px;
    border: 0;
    font-size: 16px;
    font-weight: bold;
}
.headerDevices{
    margin-bottom: 0px;
    padding-bottom: 20px;
    font-weight: bold;
}
